var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ReportYearDataList-wrapper" },
    [
      _vm.userType === 101
        ? _c("div", { staticClass: "year-data-wrapper" }, [
            _c("div", { staticClass: "search-wrapper" }, [
              _c(
                "div",
                { staticClass: "search-items" },
                [
                  _c("v-datepicker", {
                    attrs: {
                      label: "选择月份",
                      type: "month",
                      maxDate: _vm.maxDate,
                      clearable: _vm.clearable,
                    },
                    model: {
                      value: _vm.searchParams2.yearMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchParams2, "yearMonth", $$v)
                      },
                      expression: "searchParams2.yearMonth",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btn-wrapper" },
                [
                  _c("v-button", {
                    attrs: {
                      text: "搜索",
                      type: "success",
                      disabled: _vm.searchDisabled,
                    },
                    on: { click: _vm.getProjectMonthData },
                  }),
                  _c("v-button", {
                    staticClass: "export-btn",
                    attrs: { text: "导出", disabled: _vm.exportDisabled },
                    on: { click: _vm.exportProjectMonthData },
                  }),
                ],
                1
              ),
            ]),
            _vm.projectYearData.indicatorDataList &&
            _vm.projectYearData.indicatorDataList.length
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "target-data-wrapper" },
                    [
                      _vm._m(0),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(
                          _vm.projectYearData.indicatorDataList,
                          function (kpiData) {
                            return _c(
                              "el-col",
                              { key: kpiData.caliberCode, attrs: { span: 4 } },
                              [
                                _c("div", { staticClass: "grid-content" }, [
                                  _c("div", { staticClass: "caliberName" }, [
                                    _vm._v(_vm._s(kpiData.caliberName)),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "indicator",
                                      class: {
                                        "indicator-red": kpiData.highlight,
                                      },
                                    },
                                    [_vm._v(_vm._s(kpiData.indicator))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "target-data-wrapper" },
                    [
                      _vm._m(1),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(
                          _vm.projectYearData.detailDataList,
                          function (kpiDataDetail) {
                            return _c(
                              "el-col",
                              {
                                key: kpiDataDetail.caliberCode,
                                attrs: { span: 4 },
                              },
                              [
                                _c("div", { staticClass: "grid-content" }, [
                                  _c("div", { staticClass: "caliberName" }, [
                                    _vm._v(_vm._s(kpiDataDetail.caliberName)),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "indicator",
                                      class: {
                                        "indicator-red":
                                          kpiDataDetail.highlight,
                                      },
                                    },
                                    [_vm._v(_vm._s(kpiDataDetail.indicator))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _c("div", [
                  _c("div", { staticClass: "no-data-wrapper" }, [
                    _c("img", { attrs: { src: _vm.nodata, alt: "" } }),
                    _c("div", [_vm._v("暂无数据")]),
                  ]),
                ]),
          ])
        : _vm.headers.length &&
          (_vm.userType === 100 || _vm.userType === 102 || _vm.userType === 106)
        ? _c("list", {
            ref: "list",
            attrs: {
              exportPermission: "export",
              exportMethod: "new",
              searchUrl: _vm.getListURL,
              exportUrl: _vm.exportUrl,
              searchParams: _vm.searchParams,
              headers: _vm.headers,
              hasOperateColumn: false,
            },
            on: {
              "update:searchParams": function ($event) {
                _vm.searchParams = $event
              },
              "update:search-params": function ($event) {
                _vm.searchParams = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属分公司" },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.regionParams,
                        false
                      )
                    ),
                    _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属项目" },
                          model: {
                            value: _vm.searchParams.communityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "communityId", $$v)
                            },
                            expression: "searchParams.communityId",
                          },
                        },
                        "v-select2",
                        _vm.communityParams,
                        false
                      )
                    ),
                    _c("v-datepicker", {
                      attrs: {
                        label: "选择月份",
                        type: "month",
                        maxDate: _vm.maxDate,
                        clearable: _vm.clearable,
                      },
                      model: {
                        value: _vm.searchParams.yearMonth,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "yearMonth", $$v)
                        },
                        expression: "searchParams.yearMonth",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { width: "80%", title: _vm.title, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.dialogData, function (item) {
            return _c("div", { key: item.title }, [_vm._v(_vm._s(item.title))])
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("指标数据")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrapper" }, [
      _c("span", { staticClass: "tit" }, [_vm._v("数据明细")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }