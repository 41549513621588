import Vue from 'vue'
// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}kpi/region/month`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}kpi/month/export`
// 获取表头信息
const getHeadDataURL = `${API_CONFIG.butlerBaseURL}kpi/region/head`
const getHeadData = async () => {
  let res = await Vue.prototype.$axios.get(getHeadDataURL)
  let headData = []
  if (res.status === 100) {
    if (res.data && res.data.length > 0) {
      headData = res.data
    }
  }
  return headData
}
// 获取项目年度数据
const getProjectMonthDataURL = `${API_CONFIG.butlerBaseURL}kpi/community/month`

export { getListURL, exportListURL, getHeadData, getProjectMonthDataURL }
