<template>
  <div class="ReportYearDataList-wrapper">
    <div class="year-data-wrapper"
         v-if="userType===101">
      <div class="search-wrapper">
        <div class="search-items">
          <v-datepicker label="选择月份"
                        type="month"
                        v-model="searchParams2.yearMonth"
                        :maxDate="maxDate"
                        :clearable="clearable"></v-datepicker>
        </div>
        <div class="btn-wrapper">
          <v-button text="搜索"
                    type="success"
                    :disabled="searchDisabled"
                    @click="getProjectMonthData"></v-button>
          <v-button text="导出"
                    class="export-btn"
                    :disabled="exportDisabled"
                    @click="exportProjectMonthData"></v-button>
        </div>
      </div>
      <div v-if="projectYearData.indicatorDataList && projectYearData.indicatorDataList.length">
        <div class="target-data-wrapper">
          <div class="title-wrapper">
            <span class="tit">指标数据</span>
            <!-- <img :src="question" @click="showRule(0)" alt="点击查看" /> -->
          </div>
          <el-row :gutter="20">
            <el-col :span="4"
                    v-for="kpiData in projectYearData.indicatorDataList"
                    :key="kpiData.caliberCode">
              <div class="grid-content">
                <div class="caliberName">{{kpiData.caliberName}}</div>
                <div class="indicator"
                     :class="{'indicator-red': kpiData.highlight}">{{kpiData.indicator}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="target-data-wrapper">
          <div class="title-wrapper">
            <span class="tit">数据明细</span>
            <!-- <img :src="question" @click="showRule(1)" alt="点击查看" /> -->
          </div>
          <el-row :gutter="20">
            <el-col :span="4"
                    v-for="kpiDataDetail in projectYearData.detailDataList"
                    :key="kpiDataDetail.caliberCode">
              <div class="grid-content">
                <div class="caliberName">{{kpiDataDetail.caliberName}}</div>
                <div class="indicator"
                     :class="{'indicator-red': kpiDataDetail.highlight}">{{kpiDataDetail.indicator}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else>
        <div class="no-data-wrapper">
          <img :src="nodata"
               alt />
          <div>暂无数据</div>
        </div>
      </div>
    </div>
    <list ref="list"
          v-else-if="headers.length && (userType===100 || userType===102 || userType===106)"
          exportPermission="export"
          exportMethod="new"
          :searchUrl="getListURL"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams"
          :headers="headers"
          :hasOperateColumn="false">
      <template #searchSlot>
        <v-select2 label="所属分公司"
                   v-model="searchParams.regionId"
                   v-bind="regionParams"></v-select2>
        <v-select2 label="所属项目"
                   v-model="searchParams.communityId"
                   v-bind="communityParams"></v-select2>
        <v-datepicker label="选择月份"
                      type="month"
                      v-model="searchParams.yearMonth"
                      :maxDate="maxDate"
                      :clearable="clearable"></v-datepicker>
      </template>
    </list>
    <el-dialog width="80%"
               :title="title"
               :visible.sync="dialogVisible">
      <div v-for="item in dialogData"
           :key="item.title">{{item.title}}</div>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { regionParams, communityParams } from 'common/select2Params'
import { getListURL, exportListURL, getHeadData, getProjectMonthDataURL } from './api'
import { setStatusOps, ruleData } from './map'
import { createAlinkVNode, createHTMLVNode } from 'common/vdom'
import question from '@/assets/images/question.png'
import nodata from '@/assets/images/nodata.png'
import { Notification, Progress } from 'element-ui'
import { downloadHelper } from 'common/utils'

Vue.use(Progress)

const exportProgress = {
  data () {
    return {
      percentage: 0
    }
  },
  methods: {
    percentageChange (percentage) {
      this.percentage = percentage
    }
  },
  render (createElement) {
    return createElement('div', null, [
      createElement('el-progress', {
        props: {
          percentage: this.percentage
        }
      })
    ])
  }
}
export default {
  name: 'ReportYearDataList',
  components: {
    exportProgress // eslint-disable-line
  },
  data () {
    return {
      clearable: false,
      title: '',
      dialogVisible: false,
      dialogData: [],
      question,
      nodata,
      searchDisabled: false,
      exportDisabled: false,
      maxDate: new Date(),
      userType: '',
      getListURL,
      exportUrl: exportListURL,
      statusOps: setStatusOps(1),
      projectYearData: {},
      regionParams,
      communityParams,
      searchParams: {
        regionId: undefined,
        communityId: undefined,
        yearMonth: (new Date().getFullYear()) + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))
      },
      searchParams2: {
        yearMonth: (new Date().getFullYear()) + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1)),
        maxRow: 0,
        curPage: 1,
        pageSize: 20,
        row: 0,
        executeSearch: 1,
        maxPage: 0
      },
      exportParams: {
      },
      headers: []
    }
  },
  async created () {
    let userInfo = this.$store.state.userInfo
    this.userType = Number(userInfo.userType)
    if (this.userType === 101) {
      this.getProjectMonthData()
      this.exportParams = {
        communityId: userInfo.communityId
      }
    } else if (this.userType === 100 || this.userType === 102 || this.userType === 106) {
      let headData = await getHeadData()
      let list = []
      headData.forEach(item => {
        list.push({
          prop: item.caliberCode,
          label: item.caliberName,
          formatter: (row, prop) => {
            let indicator = ''
            let caliberName = ''
            let highlight = false
            let indicatorDataList = row.indicatorDataList || []
            indicatorDataList.forEach(it => {
              if (item.caliberCode === it.caliberCode) {
                indicator = it.indicator
                caliberName = it.caliberName
                highlight = it.highlight
              }
            })
            if (caliberName === '活跃率') {
              return createAlinkVNode(this, row, prop, {
                text: indicator,
                cb: this.activeRateJump
              })
            } else if (caliberName === 'APP注册审核及时率') {
              return createAlinkVNode(this, row, prop, {
                text: indicator,
                cb: this.registerAuditIntimeRateJump
              })
            } else if (caliberName === '实名及时审核率') {
              return createAlinkVNode(this, row, prop, {
                text: indicator,
                cb: this.realnameIntimeApproveRateJump
              })
            } else if (highlight) {
              indicator = `<span style="color: red;">${indicator}</span>`
              return createHTMLVNode(this, indicator)
            } else {
              return indicator
            }
          }
        })
      })
      this.headers = list
    }
  },
  methods: {
    showRule (tp) {
      let title = {
        0: '指标数据',
        1: '数据明细'
      }
      this.dialogData = ruleData[tp]
      this.title = title[tp]
      this.dialogVisible = true
    },
    async getProjectMonthData () {
      this.searchDisabled = true
      let searchParams = this.searchParams2
      let res = await this.$axios.get(getProjectMonthDataURL, {
        params: searchParams
      })
      if (res.status === 100) {
        if (res.data && res.data.length > 0) {
          this.projectYearData = res.data
        }
      }
      this.searchDisabled = false
    },
    exportProjectMonthData () {
      let searchParams = this.searchParams2
      let exportParams = this.exportParams
      let exportOptions = {
        exportUrl: exportListURL,
        postData: {
          yearMonth: searchParams.yearMonth,
          ...exportParams
        }
      }
      this.exportDisabled = true
      this.newExport(exportOptions)
    },
    async newExport (ops) {
      let _this = this
      let breadcrumb = _this.$store.getters.getBreadcrumb
      let title = `${breadcrumb.join(' > ')} - 导出进度`
      let exportProgressVNode = _this.$createElement('exportProgress', null)
      let notify = Notification({
        title: title,
        dangerouslyUseHTMLString: true,
        duration: 0,
        message: exportProgressVNode
      })
      downloadHelper.downloadByApi(ops, (res) => {
        if (res.status == 100) { // eslint-disable-line
          let data = res.data
          data.percent && exportProgressVNode.componentInstance.percentageChange(parseInt(data.percent))
          if (data.percent === '100') {
            _this.exportDisabled = false
            downloadHelper.downloadByLocation(data.filePath)
            setTimeout(() => {
              notify.close()
            }, 1000)
          }
        } else {
          _this.exportDisabled = false
          notify.close()
        }
      })
    },
    realnameIntimeApproveRateJump (row) {
      let data = {
        yearMonth: this.searchParams.yearMonth,
        communityId: row.communityId,
        paramType: 3
      }
      this.$router.push({ name: 'assessmentReport5', query: data })
    },
    registerAuditIntimeRateJump (row) {
      let data = {
        yearMonth: this.searchParams.yearMonth,
        communityId: row.communityId,
        paramType: 3
      }
      this.$router.push({ name: 'assessmentReport6', query: data })
    },
    activeRateJump (row) {
      let data = {
        yearMonth: this.searchParams.yearMonth,
        communityId: row.communityId,
        paramType: 3
      }
      this.$router.push({ name: 'assessmentReport4', query: data })
    }
  }
}
</script>
<style lang="scss" scoped>
.year-data-wrapper {
  overflow-y: auto;
  background: #fff;
  padding: 0 20px;
  .search-wrapper {
    display: flex;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 20px;
    .search-items {
      flex: 1;
    }
    .btn-wrapper {
      width: 180px;
    }
    .export-btn {
      margin-left: 10px;
    }
  }
  .target-data-wrapper {
    padding: 0 20px;
    .title-wrapper {
      margin: 20px 0;
      text-align: left;
      .tit {
        vertical-align: middle;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .grid-content {
      margin-bottom: 20px;
      .caliberName {
        margin-bottom: 10px;
      }
      .indicator-red {
        color: red;
      }
    }
  }
  .no-data-wrapper {
    padding: 30px 0;
    div {
      font-size: 16px;
    }
  }
}
</style>
